import "./App.css";

import MathComponent from "./Components/MathLiveComponent";

const App = () => {
  return (
    <div className='App'>
   
      <MathComponent />
    </div>
  );
};

export default App;
