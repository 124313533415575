import DytePlugin from "@dytesdk/plugin-sdk";
import { useState, useEffect, useRef } from "react";
import mathlive from "mathlive";
import _ from "underscore";
import  './MathliveComponent.css'

const MathComponent = () => {
  const [value, setValue] = useState("");

  useEffect(() => {
    (async () => {
      const plugin = await DytePlugin.init();

      const store = plugin.stores.create("myStore");
      const formulaElement = document.getElementById("formula");

      if (formulaElement) {
        formulaElement.addEventListener("input", (ev) => {
          throttledSave(ev.target.value);
        });
      }

      const throttledSave = _.throttle(
        async function (item) {
          await store.set("equationObj", item);
        },
        1000,
        { leading: false }
      );

      store.subscribe("equationObj", (item) => {
        if (value !== item?.equationObj) {
          formulaElement.setValue(item?.equationObj, {
            silenceNotifications: true,
          });
        }
      });
    })();
  }, [value]);

  return (
    <div className='App'>
      <math-field
        id='formula'
        onInput={(evt) => setValue(evt.target.value)}
        math-virtual-keyboard-policy='sandboxed'
      />
      <div className="canvas">{value}</div>
     
    </div>
  );
};

export default MathComponent;
